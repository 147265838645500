.lds-dual-ring-white {
    display: block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring-white:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.loadger-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
