.commisions-container {
    overflow-x: scroll;
}

.comission-row {
    display: flex;
    margin-bottom: 20px;
    width: fit-content;
}

.commision-form-row > td {
    padding: 0 !important;
}

.commision-form-row > th {
    padding: 0 !important;
}

.comission-form {
    margin-right: 20px;
    width: 400px;
}

.commission-job-position {
    width: 160px;
    margin-right: 20px;
}

.commission-add-button {
    width: 100px;
}

.no-margin {
    margin: 0px !important;
}
