:root {
    --tertiary: #fff;
    --secondary: #cdd2da;
    --sec-dark: #272626;
    --height-menu: 75px;
    --primary: #9e292f;
    --sec-light: #646464;
    --complementary: #b47b7b;

/* This is a new palette color */

    --red-color: #F44336;
    --hover-color: #EF9A9A;
    --gray-color: #444444;
    --gray-light-color: #808080;
    --hover-gray-color: #B0BEC5;
}

html,
body {
    font-family: 'cascadia', sans-serif;
    height: 100%;
    margin: 0;
    /*background-color: #383838;*/
}

#app,
.TodoApp {
    display: flex;
    height: 100%;
    overflow: auto;
    flex-flow: wrap;
}

.TodoApp.random {
    flex-flow: column;
    justify-content: center;
}

body * {
    box-sizing: border-box;
    color: var(--sec-dark);
}

header {
    width: 100%;
    height: var(--height-menu);
    background-color: var(--primary);
    box-shadow: 0 10px 16px rgba(0,0,0,0.2);
    position: absolute;
    z-index: 1;
}

header nav {
    display: flex;
    height: 100%;
}

nav a  {
    transition: .3s ease;
}

nav a:hover {
    opacity: .5;
}

header img {
    height: 100%;
    padding: 8px 16px;
}

header ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-content: center;
    list-style: none;
    color: red;
}

header ul > li {
    margin-left: 16px;
}

/*This classes apply color to elements*/
/*This class is for white-color*/
.header-icon,
header ul > li > button {
    color: var(--tertiary);
}
/*End colors classes to elements*/

button {
    padding: 24px;
    margin: 0;
    font-size: 24px;
    cursor: pointer;
    font-family: 'cascadia', sans-serif;
}

button.deactivated {
    cursor: not-allowed;
}

.pass-wrapper {
    position: relative;
    display: flex;
    margin-bottom: 14px;
}

.custom-eye-icon {
    position: absolute;
    top: 38%;
    right: 16%;
}

.custom-eye-icon:hover {
    color: grey;
    cursor: pointer;
}

.custom-link {
    color: blue;
}

header .user-container {
    vertical-align: middle;
    margin: 27px 0;
    display: inline-block;
    font-weight: bold;
}

.left-aside {
    width: 1px;
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: var(--complementary);
    position: relative;
    top: 74px;
}

.left-aside.active {
    width: 200px;
    overflow: auto;
}

.left-aside ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border-right: 1px solid var(--secondary);
    /* width: 72px; */
    height: 100%;
}

.left-aside button {
    display: block;
    width: 100%;
}

#chatContainer {
    width: 18rem;
}

.chatbot-client {
    width: 18rem;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--secondary);
}

#assistantDialog {
    flex: 1;
    display: flex;
    flex-flow: column;
    overflow: auto;
}

#userInputAssistant {
    width: 100%;
    font-size: 14px;
}

#userInputAssistant {
    width: 100%;
    font-size: 14px;
    resize: none;
    border-style: solid;
    border-color: var(--secondary);
    border-width: 1px 0 0;
    padding: 8px;
    margin: 0;
}

#userInputAssistant:active,
#userInputAssistant:focus {
    --secondary: var(--primary);
}

.dialog {
    margin: 0 1rem 1rem;
    padding: 1rem;
    background-color: var(--sec-light);
    border-radius: 8px;
    font-size: 0.8rem;
    width: calc(80% - 2rem);
}

.dialog.transparent {
    background-color: transparent;
    padding: 0;
}

.dialog.robot {
    border-radius: 8px 8px 8px 0;
}

.dialog:first-child {
    margin-top: 1rem;
}

.dialog.user {
    background-color: var(--primary);
    border-radius: 8px 8px 0 8px;
    align-self: flex-end;
}

.dialog button {
    background-color: var(--sec-light);
    font-size: 12px;
    padding: 8px 16px;
    margin: 0 8px 8px 0;
    border-radius: 8px;
    border: 0 none;
    text-align: left;
    width: max-content;
}

.dialog button:first-child {
    margin-top: 0;
}

button.transparent {
    background-color: transparent;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    padding: 10px 24px;
    height: 74px;
}

.name-user {
    font-size: 12px;
    color: #fff;
    font-weight: bold;
}

button.transparent:hover {
    background-color: #902121;
}

button.transparent.active {
    background-color: var(--primary);
}

.fa.deactivated,
.fas.deactivated {
    opacity: 0.3;
}

aside,
#jobApp {
    height: calc(100% - var(--height-menu));
    overflow: auto;
}

#jobApp {
    flex: 1;
    position: relative;
    top: 75px;
}

#chatContainer {
    width: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    position: relative;
}

#chatContainer.active {
    width: 18rem;
}

#chatToogle {
    padding: 21px 24px;
    --sec-light: var(--primary);
}

#lefMenuToggle {
    --sec-light: var(--primary);
    transition: .3s ease;
}

#lefMenuToggle:hover {
    opacity: .5;
    background-color: #902121;
}

.job-cards {
    overflow: auto;
    margin: 0;
    list-style: none;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.job-cards > li {
    width: calc(100% - 2rem);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 8px 16px var(--secondary);
    margin: 1rem;
}

@media (min-width: 1001px) {
    .job-cards > li {
        width: calc(50% - 2rem);
    }
}

@media (min-width: 1500px) {
    .job-cards > li {
        width: calc(33.3333% - 2rem);
    }
}

.job-cards li h2 {
    margin: 0 0 1rem;
}

.link,
.detail,
.blue {
    color: var(--primary);
    cursor: pointer;
}

.table-position {
    margin: 1rem;
    border: 1px solid var(--secondary);
    border-collapse: collapse;
    width: calc(100% - 2rem);
}

th,
td {
    border: 1px solid var(--secondary);
    padding: 8px 16px;
    text-align: left;
}

.back-button {
    padding: 8px 16px;
    font-size: 1rem;
    background-color: var(--sec-light);
    border: 1px solid var(--secondary);
    border-radius: 8px;
}

.back {
    padding: 1rem;
    text-align: right;
}

.job-detail {
    position: relative;
    padding: 2rem;
}

.max-col-2 {
    display: flex;
    flex-wrap: wrap;
}

.max-col-2 > * {
    width: 50%;
}

.job-detail h2 {
    margin: 0;
}

.header-description {
    display: flex;
    flex-flow: column;
    text-align: right;
}

.header-description > * {
    margin: 0 0 8px;
}

canvas {
    margin-bottom: 2rem;
}

.max-3 {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.max-3 li {
    width: calc(33.3333% - 2rem);
    margin: 1rem;
}

.legal {
    font-size: 12px;
    text-align: center;
}

.interview {
    list-style: none;
    margin: 0 0 2rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.interview li {
    padding: 1rem;
    margin: 0 0 1rem;
    background-color: var(--sec-light);
    border-radius: 8px;
    width: 100%;
}

@media (min-width: 1001px) {
    .interview li {
        margin: 0 1rem 1rem;
        width: calc(50% - 2rem);
    }
}

@media (min-width: 1500px) {
    .interview li {
        width: calc(33.3333% - 2rem);
    }
}

.feature {
    margin: 1rem 0 2rem;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.feature > li {
    margin: 0 1rem 1rem;
    width: calc(33.33% - 2rem);
    padding: 1rem;
    border-radius: 8px;
    background-color: var(--sec-light);
}

.feature > li .placeholder {
    margin-left: 1rem;
    position: relative;
    color: #a1a1a1;
}

.feature > li .placeholder > span {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    background-color: var(--sec-dark);
    border-radius: 50%;
}

.feature > li .placeholder > span.green {
    --sec-dark: var(--primary);
}

.feature > li .placeholder > span.red {
    --sec-dark: var(--complementary);
}

.login {
    width: 100%;
    height: 100%;
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    background-color: #373737;
}

.login-text {
    color: rgb(241, 233, 233);
}

/* Clase para la sección de recuperar contraseña */
.recovery-pass {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
}

.recovery-pass > .section {
    width: 100%;
}
/* Fin de clase para recuperar contraseña */

.brand {
    width: 60%;
    height: 100%;
    background-image: url(css/img/bg-login.png),
        url(css/img/mask-login-grey2.png);
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: left top, right top;
    background-size: cover, contain;
    background-color: var(--primary);
}

.ema-login {
    width: auto;
    height: 570px;
    margin: 0 auto;
    transform: translate(-140px, 0);
}

#loginForm {
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

#loginForm ul {
    margin: 0 auto;
    list-style: none;
    padding: 0;
    max-width: 500px;
    width: 100%;
}

#loginForm li {
    margin-bottom: 1rem;
}

#loginForm li:first-child {
    margin-bottom: 2rem;
}

#loginForm h1 {
    font-size: 2.5rem;
    margin: 0;
}

#loginForm h1 img {
    vertical-align: text-top;
    margin-right: 1rem;
}

#loginForm input[type='text'],
#loginForm input[type='password'] {
    border-radius: 8px;
    border: 0.1rem solid var(--secondary);
    font-size: 24px;
    padding: 1rem 2rem;
    font-family: 'cascadia', sans-serif;
    width: 25rem;
}

.loginButton {
    background-color: var(--primary);
    color: #fff;
    padding: 1rem 2rem;
    width: 25rem;
    border-radius: 8px;
    border: 0 none;
}

.loginButton:hover {
    background-color: #ca4b52;
}

.passwordButton {
    background-color: #ca4b52;
    color: #fff;
    padding: 1rem 2rem;
    width: 25rem;
    border-radius: 8px;
    border: 0 none;
}

.passwordButton:hover {
    background-color: var(--primary);
}

.emailBold {
    color: white;
}

a {
    color: var(--primary);
    text-decoration: none;
}

.custo-check {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
}

.custo-check input {
    display: none;
}

#jobApp .box {
    box-shadow: none;
    height: auto;
}

.p-dialog-maximized {
    max-height: 80vh !important;
}

.p-grid {
    align-items: flex-end;
}

.custo-check .box {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.1rem solid var(--secondary);
    border-radius: 8px;
    position: relative;
}

.custo-check .box::before {
    content: '';
    position: absolute;
    width: 0.8rem;
    height: 0.4rem;
    border-width: 0 0 0.2rem 0.2rem;
    border-style: solid;
    border-color: var(--sec-dark);
    transition: all 0.3s ease;
    top: 5px;
    left: 3px;
    opacity: 0;
    transform: rotate(-45deg) scale(2);
}

.custo-check input:checked + .box::before {
    transform: rotate(-45deg) scale(1);
    opacity: 1;
}

.center {
    text-align: center;
}

label {
    cursor: pointer;
}

.error {
    color: var(--complementary);
    font-weight: bold;
    font-size: 1.3rem;
    display: none;
}

#logout {
    padding: 10px 24px;
}

.info-vacancy {
    display: flex;
    padding: 0;
    list-style: none;
    margin: 0;
    justify-content: flex-start;
}

.info-vacancy li {
    font-size: 1.25rem;
    margin-right: 1rem;
}

.info-vacancy li:last-child {
    margin: 0;
    order: 1;
    align-self: flex-end;
    flex: 1;
    text-align: right;
}

.info-vacancy .fa-code-branch::before {
    transform: rotate(90deg);
    display: inline-block;
}

.title-vacancy {
    margin: 2rem 1rem;
}

.title-vacancy > * {
    vertical-align: middle;
}

.title-vacancy > button {
    padding: 0.5rem;
    line-height: 1rem;
}

.title-vacancy.home-title {
    margin: 2rem 2rem 0;
    display: flex;
    justify-content: space-between;
}

.title-vacancy > .primary {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    background-color: var(--primary);
    color: #fff;
    border: 0 none;
    border-radius: 8px;
}

.word {
    background-color: var(--primary);
    color: var(--sec-light);
    padding: 0.35rem 0.4rem 0.25rem 0.75rem;
    margin: 0 0.5rem 0.5rem;
    display: inline-block;
    border-radius: 1rem;
    text-transform: lowercase;
}

.word > * {
    color: var(--sec-light);
}

.word span {
    margin-right: 0.5rem;
}

.psy-model {
    margin: 0;
    list-style: none;
    padding: 0 1rem;
    display: flex;
    flex-wrap: wrap;
}

.psy-model > li {
    width: calc(33.3333% - 2rem);
    margin: 0 1rem 2rem;
    align-self: start;
}

.psy-cards {
    padding-top: 0;
}

.psy-cards h4 {
    margin: 1rem;
}

.psy-cards ul {
    list-style: none;
    padding: 0 1rem 1rem;
}

.psy-cards .title-psy {
    flex: 1;
    align-self: center;
}

.psy-cards ul > li span:not(.title-psy) {
    text-align: center;
    background-color: var(--sec-light);
    padding: 0.74rem;
    margin-left: 1rem;
    border-radius: 8px;
}

.psy-cards ul > li {
    display: flex;
    margin-bottom: 0.5rem;
}

.job-cards > li.needs-card > ul {
    display: flex;
    flex-wrap: wrap;
}

.psy-cards .needs-card ul > li {
    width: calc(50% - 2rem);
}

.psy-cards .needs-card ul > li:nth-child(2n) {
    margin-left: 2rem;
}

.job-cards > li.needs-card {
    width: calc(100% - 2rem);
}

@media (min-width: 1500px) {
    .job-cards > li.needs-card {
        width: calc(66.6666% - 2rem);
    }
}

.psy-cards .needs-card ul > li:nth-child(2n + 1) {
    margin-right: 2rem;
}

.pane-diagram {
    margin: 2rem;
    overflow: auto;
}

.chatbot-upload-area {
    position: absolute;
    left: 1px;
    top: 1px;
    background-color: rgba(255, 255, 255, 0.75);
    height: 0;
    transition: opacity 0.5s ease;
    opacity: 0;
    overflow: hidden;
    width: calc(100% - 1px);
}

.chatbot-upload-area.active {
    height: calc(100% - 2px);
    opacity: 1;
}

.upload-file {
    display: flex;
    height: 100%;
    align-items: center;
    border: 0.5rem dashed var(--primary);
    border-radius: 1rem;
    justify-content: center;
    color: var(--primary);
}

.upload-file input {
    display: none;
}

#spinner {
    position: fixed;
    left: 0;
    top: 0;
    width: 0;
    bottom: 0;
    transition: opacity 0.3s ease, width 0s linear;
    opacity: 0;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

#spinner.active {
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease, width 0s 0.3s linear;
}

#spinner ul {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

#spinner ul li {
    width: 1.5rem;
    height: 1.5rem;
    background-color: #fff;
    margin: 0.25rem;
    border-radius: 50%;
    animation: 2s spinner ease infinite;
}

#spinner ul li.shadow {
    transform: rotate3d(1, 0, 0, 70deg);
    background-color: var(--sec-dark);
    opacity: 0.3;
    animation: 2s spinnerShadow ease infinite;
}

#spinner ul li:nth-child(2),
#spinner ul li:nth-child(4) {
    animation-delay: 0.3s;
}

#spinner ul li:nth-child(3),
#spinner ul li:nth-child(6) {
    animation-delay: 0.6s;
}

/* --- ADD CLASS NAME WITCH NEW PALETTE COLOR --- */

/* Color title and text */

.gray-strench {
    color: var(--gray-color);
    font-weight: bold;
}

.gray-light {
    color: var(--gray-light-color);
}

/* STYLE ICON IN TABLE */

/* button trash active */

.p-datatable-tbody .trash {
    transition: .3s;
}

.p-datatable-tbody .trash span::before {
    color: var(--red-color);
}

.p-datatable-tbody .trash:hover {
    background-color: var(--hover-color) !important;
    border-radius: 100%;
}

.p-datatable-tbody .trash:hover span::before {
   color: #fff;
}

/* button pencil active */

.p-datatable-tbody .pencil {
    transition: .3s;
}

.p-datatable-tbody .pencil:hover {
    background-color: var(--secondary) !important;
    border-radius: 100%;
}

/* Clean style icon trash and pencil */

.p-datatable-tbody .trash,.p-datatable-tbody .trash-disabled, 
.p-datatable-tbody .pencil {
    background-color: transparent;
    border: none;
}

.p-datatable-tbody .trash-disabled span::before {
    color: var(--hover-color);
}

/* Modal size */ 

.p-dialog {
    max-width: 80vw;
}


/* Color primary button */

.container .button.primary-color, .p-dialog-content .primary-color {
    border: none;
    background-color: var(--primary);
    transition: .3s;
    margin: 12px 0;
    padding: 0 24px;
    font-weight: bold;
}

.p-datatable-wrapper .primary-color  {
    border: none;
    background-color: var(--primary);
    transition: .3s;
}

.hover-btn {
    transition: .3s ease;
}

.p-dialog-content .primary-color, .p-dialog-content .secundary-button {
    margin: 0;
    width: 100%;
    padding: 12px 24px;
    opacity: 1;
    cursor: pointer !important;
}

.p-dialog-content .primary-color.p-disabled, .p-dialog-content .secundary-button.p-disabled,
.hover-btn:hover, .p-dialog .p-dialog-header .p-dialog-header-icon:hover, .pi.pi-eye:hover {
    opacity: .3;
}

.p-dialog-content .secundary-button {
    background-color: #fff;
    border: 2px solid var(--primary);
    transition: .3s ease;
}

.p-dialog-content .secundary-button:hover {
    border: none;
    background-color: var(--primary) !important;
}

.p-dialog-content .secundary-button:hover span, .p-dialog-content .primary-color span, .p-datatable-wrapper .primary-color span {
    color: #fff;
    font-weight: bold;
}


.p-dialog-footer .confirm-btn:hover {
    cursor: pointer;
}

.p-dialog-footer .confirm-btn, .p-dialog-footer .confirm-btn.p-disabled, .p-dialog-footer .confirm-btn:hover  {
    background-color: transparent !important;
    border: none;
    transition: .3s ease;
}

.p-button.p-component.confirm-btn.p-disabled, .p-dialog-footer .confirm-btn:hover {
    opacity: .3;
}
    
.p-dialog-content .mb-5 {
    margin: 0 0 1rem !important;
}

.p-dialog-mask  .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none;
}

.pi.pi-eye {
    transition: .3s ease;
    cursor: pointer;
}

.container .p-calendar-w-btn .p-datepicker-trigger, .p-dialog-content .p-calendar-w-btn .p-datepicker-trigger,
.p-button.p-component.p-fileupload-choose, .p-button.p-component.p-confirm-dialog-accept {
    border: none;
    background-color: var(--primary);
    transition: .3s;
}

/* Hover color buttons */

.gray-color:hover, .p-button.p-component.p-confirm-dialog-reject.p-button-text:hover span {
    color: var(--hover-gray-color);
}

.p-dialog-content .disabled-button, .p-datepicker table td.p-datepicker-today > span.p-highlight,
.p-button.p-component.p-fileupload-choose.p-disabled {
    background-color: #f6c3c3 !important;
    border: none !important;
}

.p-button.p-component.p-confirm-dialog-reject.p-button-text:hover {
    background-color: transparent;
}


/* Edit color in dropdown inputs */

.container .button.primary-color:hover, .red-color:hover,
.container .button.primary-color[disabled], .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.container .p-calendar-w-btn .p-datepicker-trigger:hover,.p-dialog-content .p-calendar-w-btn .p-datepicker-trigger:hover,
.p-button.p-component.p-fileupload-choose:hover, .p-button.p-component.p-confirm-dialog-accept:hover, #pr_id_6_content .primary-color:hover,
.p-datatable-wrapper .primary-color:hover  {
    background-color: var(--hover-color) !important;
}

.p-datatable .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: #f8dede;
}

.p-datatable-tbody .p-inputtext:enabled:focus, .p-dropdown:not(.p-disabled).p-focus,
.p-inputtext:enabled:focus, .p-button:focus {
    box-shadow: 0 0 8px 2px var(--secondary) !important;
    border-color: transparent !important;
}

/*para ajustar el tamaño del dropdown en los formularios*/
.full-width > .p-dropdown {
    width: 100%;
  }
  .no-padding-left {
    padding-left: 0px;
  }

/* color icon check, cancel aned calendar */

.pi-exclamation-triangle::before {
    color: #F2C42F;
}

.pi-times::before {
    color: var(--red-color);
}

.pi-check::before {
    color: #009d08;
}

.pi-calendar::before, .p-button-label.p-clickable, .pi-plus::before,
.p-confirm-dialog-accept .p-button-label.p-c, .pi-plus::before, .pi-file::before {
    color: #fff !important;
}

/* --- FINAL NEW COLOR STYLE --- */

.bar-full,.p-inputtext.p-component  {
    width: 100%;
}

.p-inputtext.p-component {
	line-height: inherit;
}

@keyframes spinner {
    0% {
        transform: translate(0px, -1rem);
    }

    50% {
        transform: translate(0px, 1rem);
    }

    100% {
        transform: translate(0px, -1rem);
    }
}

@keyframes spinnerShadow {
    0% {
        transform: rotate3d(1, 0, 0, 70deg) scale(0.25);
        opacity: 0.15;
    }

    50% {
        transform: rotate3d(1, 0, 0, 70deg) scale(0.75);
        opacity: 0.3;
    }

    100% {
        transform: rotate3d(1, 0, 0, 70deg) scale(0.24);
        opacity: 0.16;
    }
}

@media print {
    #app > header,
    #app > aside {
        display: none !important;
    }

    html,
    body,
    #app,
    #jobApp {
        height: auto;
        overflow: visible;
        font-family: sans-serif !important;
    }

    @page {
        margin: 2cm;
    }
}

/* @media (max-width: 1920px) {
}
@media (max-width: 1366px) {

}
@media (max-width: 1024px) {
    
}
@media (max-width: 800px) {
    
} */
@media (max-width: 700px) {
    header a {
        display: flex;
    }
    header img {
        height: auto;
        width: 100%;
        align-self: center;
        padding: 0px;
    }
}