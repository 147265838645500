.lds-dual-ring {
  display: block;
  width: 64px;
  height: 64px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #0d89ec;
  border-color: #0d89ec transparent #0d89ec transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.lds-dual-ring-small {
  display: block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring-small:after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 4px solid #0d89ec;
  border-color: #0d89ec transparent #0d89ec transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loadger-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
